<!--
 * @Autor: syq
 * @Date: 2021-07-15 14:08:57
-->

<template>
  <div class="fourInarow">
    <div class="fourInarow-header">
      <div class="fourInarow-header-title">{{ title }}</div>
      <a class="fourInarow-header-btn" @click="yvlantoTheme($router, items, 'list',linkType, storeCode)">
        <span>查看更多</span>
        <van-icon :name="forward_icon_Inno" />
      </a>
    </div>
    <div class="fourInarow-details">
      <van-grid :column-num="4" :border="false">
        <van-grid-item
          v-for="item in findItems()"
          :key="item.id"
        >
          <van-image v-if="findFileType(item)===1" :src="findUrl(item)" class="details-img" />
          <video @click.stop="(e)=>videoStop(e)" v-if="findFileType(item)===2" class="details-img" :src="findUrl(item)"></video>
          <p class="goods-title">{{ item.name }}</p>
          <p class="goods-price">¥{{ (item.price || 0).toFixed(2) }}</p>
        </van-grid-item>
      </van-grid>
    </div>
  </div>
</template>

<script>
import { yvlantoTheme } from "../../../utils/homeToTheme";
import forward_icon_Inno from "../../../assets/icon/orderstatus/forward_icon_Inno@2x.png";
export default {
  props: {
    title: String,
    items: Object,
    linkType: Number
  },
  data() {
    return {
      forward_icon_Inno,
      yvlantoTheme,
    };
  },
  computed: {
    storeCode() {
      return this.$route.query["storeCode"];
    }
  },
  methods: {
    videoStop(e){
      e.stopPropagation()
    },
    findItems(){
      return this.items?.items?this.items.items.slice(0,4):[]
    },
    shopdetails(item) {
      const { itemCode, storeCode } = item;
      this.$router.push({
        path: "/shopdetails",
        query: { itemCode, storeCode },
      });
    },
    findFileType(item){
      return item?.mediaList? item.mediaList[0].type : 1
    },
    findUrl(item) {
      return item?.mediaList ? item.mediaList[0].url : undefined;
    },
  },
};
</script>
<style lang="less" scoped>
.fourInarow {
  width: 100%;
  padding: 0 6px;
  margin-top: 8px;
  border-radius: 8px;
  /deep/ .fourInarow-header {
    height: 48px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    border-radius: 8px 8px 0 0;
    .fourInarow-header-title {
      padding-left: 12px;
      font-size: 14px;
      color: @Titlecolor;
      font-weight: 600;
    }
    .fourInarow-header-btn {
      width: auto;
      height: 19px;
      padding-right: 12px;
      color: #96979B;
      span {
        display: inline-block;
        height: 19px;
        font-size: 12px;
        line-height: 19px;
      }
      .van-icon {
        width: auto;
        height: 19px;
        line-height: 19px;
        font-size: 12px;
        vertical-align: bottom;
      }
    }
  }
  .fourInarow-details {
    width: 100%;
    background: #fff;
    padding: 0 3px 0 3px;
    border-radius: 0 0 8px 8px;
    .details-img {
      width: 70px;
      height: 70px;
    }
    .goods-title {
      margin: 4px 0 0 0;
      width: 70px;
      height: 30px;
      line-height: 16px;
      font-size: 12px;
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .goods-price {
      margin: 8px 0 4px 0;
      width: 70px;
      height: 16px;
      line-height: 16px;
      font-size: 14px;
      color: @Price;
      font-family: Montserrat, Montserrat-Medium;
    }

    /deep/.van-grid-item__content {
      padding: 4px;
    }
  }
}
</style>
