/*
 * @Autor: syq
 * @Date: 2021-07-12 16:37:32
 */

import request from "../../request/request";
import {stringify} from 'qs'
// 获取商品列表
const getItemList = async () =>
  request({
    url: `/construct/getItemList`,
    method: "get",
  });
const homemeeage = async (storeCode) =>
  request({
    url: `/construct/getOneConstruct?qp-id-eq=${storeCode}`,
    method: "get",
  });

// currentPage, brandId, classid, spuid
const queryTotalItemList = async (params) => {
  return request({
    url:`/construct/getItemList?${stringify(params)}`,
    method: "get",
  })
};
export { getItemList, homemeeage, queryTotalItemList };
