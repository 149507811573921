<!--
 * @Autor: syq
 * @Date: 2021-07-09 19:26:22
-->
<template>
  <div id="storehome">
    <div class="mengban"></div>
    <!-- 搜索 -->
    <div class="search">
      <van-button
        round
        size="small"
        block
        :icon="sousuo"
        type="default"
      >
        请输入关键词进行搜索
      </van-button>
      <!-- <div class="search-title" @click="msg">
        <van-badge dot>
          <img class="imgStyle" src="../../../assets/icon/message.svg" />
        </van-badge>
      </div> -->
    </div>
    <!-- 内容 -->
    <div class="home-content">
      <div v-for="(item, index) in home" :key="index">
        <div class="swipe" v-if="item.type === 'yihangyitu'">
          <van-swipe :autoplay="3000" width="100%" height="100%">
            <van-swipe-item
              v-for="(image, index) in JSON.parse(item.content).mediaContent"
              :key="index"
            >
              <img
                class="swipeimg"
                v-lazy="findImage(image)"
              />
            </van-swipe-item>
          </van-swipe>
        </div>

        <div v-if="item.type === 'biaotibanner'&&Object.keys(findItems(item.key)).length">
          <four-in-a-row
            :title="JSON.parse(item.content).name"
            :items="findItems(item.key)"
            :linkType="JSON.parse(item.content).linkType"
          />
        </div>
        <div v-if="item.type === 'promotionArea'&&Object.keys(findItems(item.key)).length">
          <mian-img
            :imgurl="JSON.parse(item.content).leftImg[0].url"
            :title="JSON.parse(item.content).name"
            :items="findItems(item.key)"
            :linkType="JSON.parse(item.content).linkType"
            :activityNolist="JSON.parse(item.content)"
          />
          <!-- <four-in-a-row
            :title="JSON.parse(item.content).name"
            :items="findItems(item.key)"
            :linkType="JSON.parse(item.content).linkType"
          /> -->
        </div>
        <div v-if="item.type === 'productList'&&Object.keys(findItems(item.key)).length">
          <four-in-a-list
            :title="JSON.parse(item.content).name"
            :items="findItems(item.key)"
            :linkType="JSON.parse(item.content).linkType"
            :activityNolist="JSON.parse(item.content)"
          />
        </div>
        <!-- 广告图 -->
        <div v-if="item.type === 'aLineOfMany' && Object.keys(findItems(item.key)).length">
          <advertising-map :data="JSON.parse(item.content)"/>
        </div>
      </div>
    </div>
    <van-loading size="24px" v-show="homemsg" vertical>加载中...</van-loading>
  </div>
</template>

<script>
import sousuo from "../../assets/icon/souuso_icon@2x.png";
import FourInARow from "./components/FourInARow.vue";
import FourInAList from "./components/FourInAList.vue";
import TwoMoreLines from "./components/TwoMoreLines.vue";
import AdvertisingMap from './components/AdvertisingMap.vue'
import { homemeeage, queryTotalItemList} from "./service";
import { toTheme,swipeType } from "../../utils/homeToTheme";
import { Toast } from "vant";
import MianImg from './components/mianImg.vue';
const paramsType = {
  brandId: "brandId",
  classId: "classId",
  itemCode: "itemCode",
  code:"code",
  activityNo:'activityNo'
};
export default {
  components: { FourInARow, TwoMoreLines, FourInAList, MianImg,AdvertisingMap },
  data() {
    return {
      sousuo,
      toTheme,
      swipeType,
      paramsType,
      queryDataByParams: {},
      moudleType: ["biaotibanner", "productList","promotionArea",'aLineOfMany'],
      searchData: "",
      storeCode: "",
      goods: [],
      home: [],
      homemsg: false,
    };
  },

  methods: {
    async homemessage() {
      if (this.storeCode || localStorage.getItem("storeCode")) {
        this.homemsg = true;
        const res = await homemeeage(
         this.$route.query.templateId
        );
        if (res.status === "0") {
          localStorage.setItem("storeCode", res?.data?.storeCode || "");
          localStorage.setItem("storeCodeclass", res?.data?.storeCode|| "");
          this.home = JSON.parse(res?.data?.configuration || []);
          // console.log('this.home :>> ', JSON.parse(this.home[0].content));
          if (res?.data?.configuration) {
          JSON.parse(res.data.configuration).forEach(async (item, index) => {
            if (this.moudleType.includes(item.type)) {
              const itemContent = JSON.parse(item.content);
              const params = {
                // currentPage: 1,
                storeCode:this.storeCode || localStorage.getItem("storeCode"),
                [this.paramsType[this.swipeType[itemContent.linkType]]]:
                  itemContent?.rule?.itemsRule?.itemListRuleContent?.toString(),
              };
              const res = await queryTotalItemList(params);
              if (res.status === "0") {
                this.$set(this.queryDataByParams,item.key,{items: res.data.items,
                    rowNum: itemContent?.rowNum
                      ? Number(itemContent.rowNum)
                      : undefined,})
              } else {
                Toast(res.msg);
              }
            }
          });
          }
          this.homemsg = false;

        } else {
          this.homemsg = false;
          Toast.fail(res.msg);
        }
      }
    },

    findItems(key){
      return this.queryDataByParams[key] ? this.queryDataByParams[key]: {}
    },

    findImage(item) {
      return (
        item?.mediaContent[0].url ||
        "http://uat-oss-business-middle-platform.oss-cn-shenzhen.aliyuncs.com/202108202047596941428700340054843393.png?Expires=1944823679&OSSAccessKeyId=LTAI5tMBSdRFFsKwy54k3F3J&Signature=c%2B%2FQNCjX6kK7mi1wrIZlpdJjCMg%3D"
      );
    },
    tosearch(){
      this.$router.push({ 
        path: '/storesearch', 
        query: { 
          type: 'defaultSearch' , 
          storeCode: this.storeCode
          } 
      }) 
    },
    msg() {
      this.$router.push("/message");
    },

    searchMore() {
      this.$router.push("/storetheme");
    },
  },

  created() {
    // localStorage.setItem("keyid", this.$route.query.storeid || "");
    localStorage.setItem("storeCodeclass", this.$route.query.storeCode || "");
    this.storeCode = this.$route.query.storeCode;
    this.homemessage();
  },
};
</script>

<style lang="less" scoped>
#storehome {
  width: 100%;
  height: 100%;
  padding-bottom: 8px;
  display: flex;
  flex-direction: column;
  background-color: @BackgroundColor;
  /deep/ .search {
    display: flex;
    align-items: center;
    padding: 11px 12px;
    background-image: @GradientColor;
    .van-button__text {
      color: #96979b;
    }
    .van-button--small {
      height: 32px;
      border: none;
    }
    .search-title {
      width: 32px;
      height: 44px;
      padding: 8px 0 8px 8px;
      .van-badge__wrapper {
        padding-top: 2px;
        .van-badge--dot {
          border-color: #ee0a24;
        }
        .van-badge--fixed {
          top: 3px;
          right: 6px;
        }
        .imgStyle {
          width: 24px;
          height: 24px;
          color: #fff;
        }
      }
    }
  }
  .home-content {
    flex: 1 1 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    .swipe {
      width: 100%;
      height: 138px;
      .van-swipe {
        width: 100%;
        height: 100%;
        .swipeimg {
          width: 100%;
          height: 100%;
        }
      }
    }
    .home-menu {
      width: 100%;
      height: 106px;
      padding: 0 12px;
      margin: 9px 0;
      .van-grid {
        width: 100%;
        height: 100%;
        /deep/.van-grid-item__content {
          padding: 8px;
          .van-grid-item__icon {
            font-size: 50px;
          }
          .van-grid-item__text {
            font-size: 14px;
          }
        }
      }
    }
    .alone {
      width: 100%;
      height: 96px;
      padding: 0 12px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
<style lang="less"  scoped>
.mengban{
  position: fixed;
  top: 750px;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}
</style>